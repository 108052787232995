<template>
    <div style="width:100%">
        <van-divider v-if="eligiblePromos.length!==1||true"
                     style="margin:1.5rem 1rem 1rem;width:calc(100% - 2rem);color:var(--van-primary-color); border-color:var(--van-primary-color)">
            {{ t('Aktywne Promocje') }}
        </van-divider>
        <br v-else>
        <VanCellGroup v-if="eligiblePromos.length!==1||true" style="width:100%">
            <Suspense >
                <VanCell :title="t('Wskaż promocje, do którch zgłaszasz paragon')"  @click="showExplanation=!showExplanation">
                    <template #extra>
                        <icon name="solar:question-circle-outline"
                              size="24"
                              color="#80808060"></icon>
                    </template>
                    <template #label>
                        <Markdown v-if="showExplanation" class="animate__animated animate__fadeIn"
                                  :content="t('promosExplanation')"></Markdown>
                    </template>
                </VanCell>
            </Suspense>
            <ReceiptsManualEntryPromo v-for="promo in eligiblePromos"
                                      :promo="promo"
                                      :key="promo.id"
                                      :receipt="receipt"
                                      @change="onStatusChange">
            </ReceiptsManualEntryPromo>
        </VanCellGroup>
        <div v-else style="margin: 1rem;">
            <ReceiptsManualEntryPromo :promo="eligiblePromos[0]"
                                      :receipt="receipt"
                                      message-only
                                      @change="onStatusChange">
            </ReceiptsManualEntryPromo>
        </div>

        <div style="text-align: center;margin:2rem 0;">
            <VanButton type="primary"
                       :disabled="!isReadyToSave || busy"
                       round
                       style="padding: 0 2rem;"
                       @click="onSave">
                <p v-if="!isVerifying">{{ t('Zapisz dane paragonu') }}</p>
                <VanLoading v-else size="24" color="white">{{t('...sprawdzanie warunków')}}</VanLoading>
            </VanButton>
        </div>
    </div>


</template>

<script setup>
const props = defineProps({
    receipt: Object,
    busy:Boolean
})

const emit = defineEmits(['submit'])
const store = useStore()
const dayjs = useDayjs()
const {member} = useMember()


const eligibleTypes = ['program','competition','lottery','game','quiz']
const eligiblePromos = computed(()=>_filter(store.promos,p=> {
    return _includes(eligibleTypes, p.type)
            && (p.endDate > new Date())
            && p.purchaseAware
            && p.active && (!p.testing || member?.profile?.testUser)
}))
console.log('eligible promos',eligiblePromos.value)
const statuses = ref([])
function onStatusChange(promo,status){
    //console.log('statuses change',promo,status)
    const index = _findIndex(statuses.value,s=>s.id===promo)
    if(index===-1) statuses.value.push({id:promo,...status})
    else statuses.value[index] = {id:promo,...status}
}
const isVerifying = computed(()=>{
    return isEligible.value && _reduce(statuses.value,(acc,s)=>{
        return s.verifying || acc
    },false)
})
const isOk = computed(()=>{
    return isEligible.value && _reduce(statuses.value,(acc,s)=>{
        return !s.message || acc
    },false)
})
const isReadyToSave = computed(()=>{
    return isEligible.value && _reduce(statuses.value,(acc,s)=>{
        return acc || s.register
    },false)
})


const isEligible = computed(()=>!!props.receipt.date && !!props.receipt.tenant && !!props.receipt.number && !!props.receipt.value)

function onSave(){
    emit('submit',statuses.value)
}
const showExplanation = ref(false)




const {t} = useLocale('receiptManualEntry',{
    en:{
        'Sprawdź poprawność odczytanych danych':'Check the correctness of the data',
        'Uzupełnij brakujące dane paragonu':'Fill in the missing receipt details',
        'Aktywne Promocje':'Currently active promotions',
        'Zapisz dane paragonu':'Save receipt data',
        '...sprawdzanie warunków':'...checking conditions',
        'Wskaż promocje, do którch zgłaszasz paragon':'Indicate for which promos you submit a receipt',
        'promosExplanation':'We currently have more than one promotion for which you can enter your receipt. ' +
                'By selecting a promotion, you agree to its terms and conditions.',
        'dodaj':'add',
        'paragon spełnia warunki tej promocji':'the receipt meets this promo conditions',
        'Sklep jest wykluczony z tej promocji':'The shop is excluded from this promotion',
        'Akceptujemy zakupy dokonane po ':'We accept purchases made after ',
        'Akceptujemy paragony do ${maxAge} dni po zakupie':'We accept receipts up to ${maxAge} days after purchase'

    },
    uk: {

    },
    pl:{
        'promosExplanation':'Często mamy więcej niż jedną promocję, do której możesz zgłosić swój paragon. ' +
                'Zanaznacz promocje, w których chcesz wziąć udział.'
    }

})
</script>

<style scoped>
.display-area {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>